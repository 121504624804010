import { createClient, defaultExchanges, subscriptionExchange } from 'urql';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { env } from './env';

export const authRoutesGqlClient = (auth: any) =>
  createClient({
    url: `https://${env.graphqlURL}`,
    requestPolicy: 'cache-and-network',
    fetchOptions: () => {
      return {
        headers: {
          authorization: `Bearer ${auth.token}`,
          'x-platform': 'IMPLEMENTATION_DASHBOARD',
        },
        credentials: 'include',
      };
    },
    exchanges: [
      ...defaultExchanges,
      subscriptionExchange({
        forwardSubscription(operation) {
          return new SubscriptionClient(`wss://${env.graphqlURL}`, {
            reconnect: true,
            connectionParams: {
              headers: {
                Authorization: `Bearer ${auth.token}`,
              },
            },
          }).request(operation);
        },
      }),
    ],
  });

export const openRoutesGqlClient = createClient({
  url: `${env.apiURL}/graphql`,
  fetchOptions: () => {
    return {
      headers: {
        'x-platform': 'IMPLEMENTATION_DASHBOARD',
      },
      credentials: 'include',
    };
  },
});
