import React from 'react';
import { Provider } from 'urql';
import { Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { openRoutesGqlClient, authRoutesGqlClient } from './config/graphql';
import AuthContext from './contexts/AuthContext';
import { SideBarContextProvider } from './contexts/SideBarContext';
import PublicRoutesLayout from './layouts/PublicRoutesLayout';
import AppRoutesLayout from './layouts/AppRoutesLayout';
import Loader from './components/Loader';
import {
  publicRoutes,
  adminRoutes,
  rolloutRepRoutes,
  RouteType,
} from './utils/routes';
import { Roles } from './constants';
import { UserContextProvider } from './contexts/UserContext';
import useEvent from './contexts/EventContext';
import { Events } from './constants/events';
import { env } from './config/env';

const RouteProvider = () => {
  const { auth } = React.useContext(AuthContext);
  const location = useLocation();
  const { registerEvent } = useEvent();

  React.useEffect(() => {
    if (env.recordEvents) {
      registerEvent({
        eventType: Events.PAGE_VIEW,
      });
    }
  }, [location.pathname]);

  if (!auth?.id || !auth?.token || !auth?.roles.length) {
    return (
      <PublicRoutesLayout>
        <Provider value={openRoutesGqlClient}>
          <React.Suspense fallback={<Loader height="100vh" width="100vw" />}>
            <Switch>
              {publicRoutes.map((routeObj: RouteType) => (
                <Route
                  path={routeObj.path}
                  exact={routeObj.path === '/'}
                  key={routeObj.path}
                >
                  {routeObj.component}
                </Route>
              ))}
            </Switch>
          </React.Suspense>
        </Provider>
      </PublicRoutesLayout>
    );
  }

  if (
    auth.roles.includes(Roles.ROLLOUT_REP) &&
    !auth.roles.includes(Roles.ADMIN)
  ) {
    return (
      <Provider value={authRoutesGqlClient(auth)}>
        <UserContextProvider>
          <SideBarContextProvider>
            <AppRoutesLayout>
              <React.Suspense
                fallback={<Loader height="100vh" width="100vw" />}
              >
                <Switch>
                  {rolloutRepRoutes.map((routeObj: RouteType) => (
                    <Route
                      path={routeObj.path}
                      exact={routeObj.path === '/'}
                      key={routeObj.path}
                    >
                      {routeObj.component}
                    </Route>
                  ))}
                </Switch>
              </React.Suspense>
            </AppRoutesLayout>
          </SideBarContextProvider>
        </UserContextProvider>
      </Provider>
    );
  }

  return (
    <Provider value={authRoutesGqlClient(auth)}>
      <UserContextProvider>
        <SideBarContextProvider>
          <AppRoutesLayout>
            <React.Suspense fallback={<Loader height="100vh" width="100vw" />}>
              <Switch>
                {adminRoutes.map((routeObj: RouteType) => (
                  <Route
                    path={routeObj.path}
                    exact={routeObj.path === '/'}
                    key={routeObj.path}
                  >
                    {routeObj.component}
                  </Route>
                ))}
              </Switch>
            </React.Suspense>
          </AppRoutesLayout>
        </SideBarContextProvider>
      </UserContextProvider>
    </Provider>
  );
};

export default RouteProvider;
