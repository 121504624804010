import { env } from '../config/env';
import { TokenType } from '../types';

let tokenData: TokenType = { id: '', token: '', roles: ['user'] };

// expires 15second earlier then actual timeout
export const expiresIn = 60 * 45 * 1000;

export const setRefreshToken = (token: TokenType): void => {
  tokenData = token;
};

export const getRefreshToken = async (): Promise<TokenType> => {
  if (!tokenData.token || !tokenData.id) {
    const res = await fetch(`${env.apiURL}/v1/auth/token`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'x-platform': `IMPLEMENTATION_DASHBOARD`,
      },
    });
    if (res.status > 300) {
      const error = new Error(res.statusText);
      throw error;
    }
    const data = await res.json();
    if (data) {
      tokenData = data;
    } else {
      throw new Error('Unauthorized');
    }
  }
  return tokenData;
};
