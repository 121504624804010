import React from 'react';

const Login = React.lazy(() => import('../pages/Login'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const Overview = React.lazy(() => import('../pages/Overview'));
const Members = React.lazy(() => import('../pages/Members'));
const Surveys = React.lazy(() => import('../pages/Surveys'));
const RolloutReps = React.lazy(() => import('../pages/RolloutReps'));
const Schools = React.lazy(() => import('../pages/Schools'));
const NotFound = React.lazy(() => import('../pages/404'));
const SchoolDashboard = React.lazy(() => import('../pages/SchoolDashboard'));
const Notifications = React.lazy(() => import('../pages/Notifications'));
const DataDashboard = React.lazy(() => import('../pages/DataDashboard'));
const Events = React.lazy(() => import('../pages/Events'));
const StripeCoupons = React.lazy(() => import('../pages/StripeCoupons'));
const CanaryRollout = React.lazy(() => import('../pages/CanaryRollout'));
const Subscriptions = React.lazy(() => import('../pages/Subscriptions'));
const OTP = React.lazy(() => import('../pages/OTP'));
const Donations = React.lazy(() => import('../pages/Donations'));
const InviteCodes = React.lazy(() => import('../pages/InviteCodes'));
const CohortTrainings = React.lazy(() => import('../pages/CohortTrainings'));
const Cohort = React.lazy(() => import('../pages/Cohort'));
const SchoolSignup = React.lazy(() => import('../pages/SchoolSignup'));

export interface RouteType {
  path: string;
  component: React.ReactElement;
  title: string;
}

export const publicPaths: string[] = ['/'];

export const isPublicPath = (path: string): boolean => {
  return publicPaths.includes(path);
};

// Make sure new routes are added before `*` path otherwise it will not match new route path.

export const publicRoutes: RouteType[] = [
  {
    path: '/',
    component: <Login />,
    title: 'Login',
  },
  {
    path: '*',
    component: <Login />,
    title: 'No match found',
  },
];

export const adminRoutes: RouteType[] = [
  {
    path: '/',
    component: <Dashboard />,
    title: 'Dashboard',
  },
  {
    path: '/schools',
    component: <Schools />,
    title: 'Schools',
  },
  {
    path: '/school/signup',
    component: <SchoolSignup />,
    title: 'School Signup',
  },
  {
    path: '/subscriptions/:page',
    component: <Subscriptions />,
    title: 'Subscriptions',
  },
  {
    path: '/overview/:id',
    component: <Overview />,
    title: 'Overview',
  },
  {
    path: '/members/:orgId',
    component: <Members />,
    title: 'Members',
  },
  {
    path: '/cohort/:orgId',
    component: <Cohort />,
    title: 'Cohort',
  },
  {
    path: '/surveys/:orgId',
    component: <Surveys />,
    title: 'Surveys',
  },
  {
    path: '/rollout-reps',
    component: <RolloutReps />,
    title: 'Rollout Reps',
  },
  {
    path: '/notifications',
    component: <Notifications />,
    title: 'Notification',
  },
  {
    path: '/data-dashboard/:page',
    component: <DataDashboard />,
    title: 'Data Dashboard',
  },
  {
    path: '/stripe-coupons',
    component: <StripeCoupons />,
    title: 'Stripe Coupons',
  },
  {
    path: '/canary-rollout',
    component: <CanaryRollout />,
    title: 'Canary Rollout',
  },
  {
    path: '/otp',
    component: <OTP />,
    title: 'OTP',
  },
  {
    path: '/donations',
    component: <Donations />,
    title: 'Donations',
  },
  {
    path: '/invite-codes',
    component: <InviteCodes />,
    title: 'Invite Codes',
  },
  {
    path: '/cohort-trainings',
    component: <CohortTrainings />,
    title: 'Cohort Trainings',
  },
  {
    path: '/events',
    component: <Events />,
    title: 'Events',
  },
  {
    path: '*',
    component: <NotFound />,
    title: 'No match found',
  },
];

export const rolloutRepRoutes: RouteType[] = [
  {
    path: '/',
    component: <SchoolDashboard />,
    title: 'Dashboard',
  },
  {
    path: '/overview/:id',
    component: <Overview />,
    title: 'Organization',
  },
  {
    path: '/members/:orgId',
    component: <Members />,
    title: 'Members',
  },
  {
    path: '/surveys/:orgId',
    component: <Surveys />,
    title: 'Surveys',
  },
  {
    path: '/notifications',
    component: <Notifications />,
    title: 'Notification',
  },
  {
    path: '*',
    component: <NotFound />,
    title: 'No match found',
  },
];
