import React from 'react';
import { gql } from '@urql/core';

import AuthContext from './AuthContext';
import { env } from '../config/env';
import { openRoutesGqlClient } from '../config/graphql';

interface EventInput {
  eventType: string;
  eventData?: {
    eventClass?: string;
    resourceID?: string;
    resourceType?: string;
    message?: string;
    platform?: string;
  };
}

interface EventContextInterface {
  registerEvent: (input: EventInput) => void;
}

export const EventContext = React.createContext<EventContextInterface>({
  registerEvent: () => {},
});

export const EventContextProvider: React.FC = ({ children }) => {
  const { auth } = React.useContext(AuthContext);

  const registerEvent = (input: EventInput) => {
    if (env.recordEvents) {
      let uid = auth?.id || sessionStorage.getItem('cfc_session');
      if (!uid) {
        uid = `session_${Date.now().toString()}`;
        sessionStorage.setItem('cfc_session', uid);
      }

      openRoutesGqlClient
        .mutation(
          gql`
            mutation registerKafkaEvent($data: RegisterKafkaEventInput!) {
              register_kafka_event(event: $data) {
                id
                eventType
                context {
                  ip
                  locale
                }
              }
            }
          `,
          {
            data: {
              eventType: input.eventType,
              eventClass: input.eventData?.eventClass || 'fact',
              userId: uid,
              path: window.location.pathname,
              url: window.location.toString(),
              locale: window.navigator.language,
              pageTitle: document.title,
              platform: `IMPLEMENTATION_DASHBOARD`,
              ...(input.eventData || {}),
            },
          },
        )
        .toPromise();
    }
  };

  return (
    <EventContext.Provider value={{ registerEvent }}>
      {children}
    </EventContext.Provider>
  );
};

const useEvent = () => React.useContext(EventContext);

export default useEvent;
