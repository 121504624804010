import { gql } from '@urql/core';

export const GET_NOTIFICATION_COUNT = gql`
  subscription getNotifications($userId: uuid!) {
    auth_notifications_aggregate(
      where: { userId: { _eq: $userId }, read: { _eq: false } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getNotifications = (filters: { read?: boolean }) => {
  let readFilter = ``;
  if (filters.read !== undefined) {
    readFilter = `read: {_eq: ${filters.read}}`;
  }

  return gql`
    query getNotifications($userId: uuid!, $limit: Int, $offset: Int) {
      auth_notifications_aggregate(
        where: { userId: { _eq: $userId }, ${readFilter} }
      ) {
        aggregate {
          count
        }
      }
      auth_notifications(
        limit: $limit
        offset: $offset
        where: { userId: { _eq: $userId }, ${readFilter} }
        order_by: {
          createdAt: desc
        }
      ) {
        id
        read
        createdAt
        notificationType
        organization {
          id
          name
          rollout_status {
            rolloutStatus
            communicationStatus
            rolloutStatusUpdatedAt
          }
          organization_subscriptions {
            createdAt
              subId
              subType
              customer
              amount
              coupon
              start
              end
              status
              plan
              quantity
          }
        }
      }
    }
  `;
};

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($id: uuid!) {
    update_auth_notifications(
      where: { id: { _eq: $id } }
      _set: { read: true }
    ) {
      affected_rows
    }
  }
`;

export const MARK_ALL_READ = gql`
  mutation updateNotification($userId: uuid!) {
    update_auth_notifications(
      where: { userId: { _eq: $userId }, read: { _eq: false } }
      _set: { read: true }
    ) {
      affected_rows
    }
  }
`;
