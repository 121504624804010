import React from 'react';
import { TokenType } from 'types';

import Loader from '../components/Loader';
import {
  getRefreshToken,
  setRefreshToken,
  expiresIn,
} from '../utils/authToken';
import { isPublicPath } from '../utils/routes';

interface AuthContextInterface {
  auth?: TokenType;
  setAuth: (arg0?: any) => void;
}

const AuthContext = React.createContext<AuthContextInterface>({
  auth: undefined,
  setAuth: () => {},
});

export const AuthContextProvider: React.FC = ({ children }) => {
  const [auth, setAuth] = React.useState<TokenType>({
    id: '',
    token: '',
    roles: ['user'],
  });
  const [loading, setLoading] = React.useState(true);
  let intervalRef: any = null;

  const setAuthData = async (data: {
    id: string;
    token: string;
    roles: string[];
  }) => {
    if (!data.id || !data.token) {
      // @ts-ignore
      // if (window.analytics) {
      //   // @ts-ignore
      //   window.analytics.identify(Date.now());
      // }

      // call logout mutation to remove auth token from redis store.
      if (auth.id || auth.token) {
        setAuth({ id: '', token: '', roles: [] });
      }
      setRefreshToken({
        id: '',
        token: '',
        roles: [],
      });

      // clear interval to refetch access token
      if (intervalRef) {
        clearInterval(intervalRef);
      }

      if (!isPublicPath(window.location.pathname)) {
        window.location.replace('/');
      }
    } else {
      // @ts-ignore
      // if (window.anlytics) {
      //   // @ts-ignore
      //   window.analytics.identify(data.id, {
      //     email: data.email,
      //     name: `${data.firstName} ${data.lastName}`,
      //   });
      // }
    }
    setAuth(data);
    setRefreshToken(data);

    // set new auth token
    intervalRef = setInterval(async () => {
      const newTokenData = await getRefreshToken();
      setAuth(newTokenData);
    }, expiresIn);
  };

  React.useEffect(() => {
    let isCancelled = false;

    const getToken = async () => {
      try {
        const token = await getRefreshToken();

        setLoading(false);
        if (!isCancelled) {
          setAuthData(token);
        }
      } catch (err) {
        setAuthData({ id: '', token: '', roles: ['user'] });
        setLoading(false);
      }
    };

    getToken();

    return function cleanup() {
      isCancelled = true;
      if (intervalRef) {
        clearInterval(intervalRef);
      }
    };
  }, []);

  if (loading) {
    return <Loader height="100vh" size="large" />;
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth: setAuthData }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
