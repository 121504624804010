import React from 'react';
import { useClient } from 'urql';

import Loader from '../components/Loader';
import { GET_USER } from '../graphql/user';
import AuthContext from './AuthContext';
import { UserType } from '../types';

interface UserContextInterface {
  user?: UserType;
  loading: boolean;
  setUser: (arg0?: any) => void;
}

const UserContext = React.createContext<UserContextInterface>({
  user: undefined,
  loading: false,
  setUser: () => {},
});

export const UserContextProvider: React.FC = React.memo(({ children }) => {
  const [user, setUser] = React.useState<UserType>();
  const { auth } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const client = useClient();

  const setData = async () => {
    setLoading(true);
    const { data } = await client
      .query(GET_USER, { id: auth?.id || '' })
      .toPromise();
    if (data) {
      const userData: UserType = data.auth_users[0] as UserType;
      setUser({
        ...userData,
        isAdmin: (userData?.user_roles || []).some(
          (i: any) => i.role.name === 'admin',
        ),
      });
    }
    setLoading(false);
  };
  React.useEffect(() => {
    setData();
  }, []);

  if (loading) {
    return <Loader height="100vh" size="large" />;
  }

  return (
    <UserContext.Provider value={{ user, loading, setUser }}>
      {children}
    </UserContext.Provider>
  );
});

export default UserContext;
