import React from 'react';
import { useSubscription } from 'urql';
import { Link } from 'react-router-dom';
import { BellOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { GET_NOTIFICATION_COUNT } from '../graphql/notification';
import { Badge } from 'antd';

const Icon = styled(BellOutlined)`
  color: gray;
  font-size: 18px;
  margin-right: 10px;
  &:hover {
    color: #2fc0ce;
  }
`;

const NotificationBadge: React.FC<{ userId: string }> = ({ userId }) => {
  const [{ data }] = useSubscription({
    query: GET_NOTIFICATION_COUNT,
    variables: {
      userId: userId,
    },
  });

  const count = data?.auth_notifications_aggregate.aggregate.count || 0;
  return (
    <div
      style={{
        width: 50,
      }}
    >
      <Link to="/notifications">
        {count > 0 ? (
          <Badge count={count}>
            <Icon />
          </Badge>
        ) : (
          <Icon />
        )}
      </Link>
    </div>
  );
};

export default NotificationBadge;
