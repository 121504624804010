export const Events = {
  PAGE_VIEW: `IM_PAGE_VIEW`,
  LOGIN_ATTEMPT: `IM_LOGIN_ATTEMPT`,
  LOGIN_SUCCESSFUL: `IM_LOGIN_SUCCESSFUL`,
  LOGIN_FAILED: `IM_LOGIN_FAILED`,
  LOGOUT: `IM_LOGOUT`,
  CHANGE_ROLE: 'IM_CHANGE_ROLE',
  CHANGE_GROUP: 'IM_CHANGE_GROUP',
  REMOVE_MEMBER: 'IM_REMOVE_MEMBER',
  CHANGE_EMAIL: 'IM_CHANGE_EMAIL',
  RESEND_INVITE: 'IM_RESENT_INVITE',
  VIEW_PROFILE: 'IM_VIEW_PROFILE',
  INVITE_MEMBERS: 'IM_INVITE_MEMBERS',
  CANCEL_SUBSCRIPTION: 'IM_CANCEL_SUBSCRIPTION',
  UPDATE_GROUP: 'IM_UPDATE_GROUP',
  SAVE_GROUP: 'IM_SAVE_GROUP',
  SAVE_ROLLOUT_STATUS: 'IM_SAVE_ROLLOUT_STATUS',
  SAVE_FAMILY_ROLLOUT_STATUS: 'IM_SAVE_FAMILY_ROLLOUT_STATUS',
  SAVE_ROLLOUT_DATE: 'IM_SAVE_ROLLOUT_DATE',
  SAVE_ROLLOUT_COMMUNICATION_STATUS: 'IM_SAVE_ROLLOUT_COMMUNICATION_STATUS',
  SAVE_FEATURE: 'IM_SAVE_FEATURE',
  SAVE_FEATURE_MAPPINGS: 'IM_SAVE_FEATURE_MAPPINGS',
  EDIT_FEATURE: 'IM_EDIT_FEATURE',
  SEND_EMAIL: 'IM_SEND_EMAIL',
  CHANGE_END_DATE: 'IM_CHANGE_END_DATE',
  CHANGE_SCHEDULE: 'IM_CHANGE_SCHEDULE',
  UPDATE_ARCHIVE_STATE: 'IM_UPDATE_ARCHIVE_STATE',
  REOPEN_SURVEY: 'IM_REOPEN_SURVEY',
  REMOVE_SURVEY: 'IM_REMOVE_SURVEY',
  SET_SUBSCRIPTION_RENEWAL: 'IM_SET_SUBSCRIPTION_RENEWAL',
  STOP_AUTO_SUBSCRIPTION_RENEWAL: 'IM_STOP_AUTO_SUBSCRIPTION_RENEWAL',
  CANCEL_SUBSCRIPTION_RENEWAL: 'IM_CANCEL_SUBSCRIPTION_RENEWAL',
  SUBSCRIPTION_RENEWED: 'IM_SUBSCRIPTION_RENEWED',
  ADD_COHORT: 'IM_ADD_COHORT',
  UPDATE_COHORT: 'IM_UPDATE_COHORT',
  ADD_COHORT_TRAINING: 'IM_ADD_COHORT_TRAINING',
  UPDATE_COHORT_TRAINING: 'IM_UPDATE_COHORT_TRAINING',
  CERTIFICATE_ISSUED: 'IM_CERTIFICATE_ISSUED',
  REMOVE_COHORT: 'IM_REMOVE_COHORT',
  CHANGE_COHORT: 'IM_CHANGE_COHORT',
  ASSIGN_COHORT: 'IM_ASSIGN_COHORT',
  ENABLE_COHORT_MODE: 'IM_ENABLE_COHORT_MODE',
  ADD_COHORT_TRAINING_MEMBER: `IM_ADD_COHORT_TRAINING_MEMBER`,
  REMOVE_COHORT_TRAINING_MEMBER: `IM_REMOVE_COHORT_TRAINING_MEMBER`,
  ADMIN_ORGANIZATION_SIGN_UP: `IM_ADMIN_ORGANIZATION_SIGN_UP`,
  DISABLE_COHORT: 'IM_DISABLE_COHORT',
  REMOVE_COHORT_MEMBER: 'IM_REMOVE_COHORT_MEMBER',
  REMOVE_MEMBER_FROM_SURVEY: `REMOVE_MEMBER_FROM_SURVEY`,
};

export const EventResources = {
  USER: `IM_USER`,
  MEMBER: `MEMBER`,
  ORGANIZATION: `ORGANIZATION`,
  GROUP: `GROUP`,
  FEATURE: 'FEATURE',
  SURVEY: 'SURVEY',
  SUBSCRIPTION: `SUBSCRIPTION`,
  COHORT: `COHORT`,
  COHORT_TRAINING: `COHORT_TRAINING`,
  CERTIFICATE: `CERTIFICATE`,
  COHORT_TRAINING_MEMBER: `COHORT_TRAINING_MEMBER`,
  SURVEY_MEMBER: `SURVEY_MEMBER`,
};
