import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Flex from './Flex';

const Wrapper = styled(Flex)`
  height: ${(props: any) => props.height || 'auto'};
  width: ${(props: any) => props.width || 'auto'};
`;

const Loader: React.FC<{
  height?: string;
  width?: string;
  size?: 'small' | 'default' | 'large' | undefined;
}> = ({ height, width, size }) => {
  return (
    <Wrapper
      justifyContent="center"
      alignItems="center"
      height={height}
      width={width}
    >
      <Spin
        size={size}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      />
    </Wrapper>
  );
};

Loader.defaultProps = {
  size: 'default',
};

export default Loader;
