import styled from 'styled-components';
import { Layout, Drawer, Menu } from 'antd';
import Flex from '../components/Flex';
import { media } from '../utils/mediaQueries';

export const Logo = styled.img`
  height: 34px;
  background: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  margin-left: 40px;
`;

export const StyledSider = styled(Layout.Sider)`
  display: block;
  background: #fff;
  position: fixed;
  height: calc(100vh - 70px);
  overflow-y: scroll;
`;

export const StyledDrawer = styled(Drawer)`
  ${media.lg`
    display: none;
  `}
`;

export const FlexHeader = styled(Flex)`
  height: 64px;
  margin-right: 11px;
`;

export const PopoverWrapper = styled.div`
  cursor: pointer;
  margin: 0;
  ${media.lg`
    margin: 0 40px;
  `};
`;

export const NameContainer = styled.span`
  margin-left: 7px;
  color: rgb(129, 141, 150);
  /* font-family: 'Varela Regular'; */
  display: none;
  margin-right: 3px;
  ${media.lg`
    display: block;
  `}
`;

export const AvatarMenuContainer = styled.div`
  cursor: pointer;
  flex: 2 1 0%;
  margin-left: 15px;
  ${media.lg`
    margin-left: 0;
  `}
`;

export const StyledHeader = styled(Layout.Header)`
  background: #fff;
  padding: 0;
  position: fixed;
  z-index: 1;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
`;

export const StyledSideMenu = styled(Menu)`
  border-right: 0 !important;
  .ant-menu-item-selected {
    background-color: transparent !important;
  }
  .ant-menu-item::after {
    border-right: 0 !important;
  }
`;
