import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './contexts/AuthContext';
import { EventContextProvider } from './contexts/EventContext';
import Router from './Router';

const App: React.FC = () => (
  <BrowserRouter>
    <AuthContextProvider>
      <EventContextProvider>
        <Router />
      </EventContextProvider>
    </AuthContextProvider>
  </BrowserRouter>
);

export default App;
