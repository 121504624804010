export const LOGIN = `
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      token
      user_roles {
        role {
          name
        }
      }
    }
  }
`;

export const GET_ALL_USERS = `
  query getAllUsers($limit: Int!, $offset: Int!) {
    auth_users_aggregate(
      order_by: { email: asc }
      where: {
        _and: [
          { isDeleted: { _eq: false } }
          {
            _not: {
              user_roles: {
                roleId: { _in: ["598c58a6-23cd-48a1-899a-d6f95c73aebc"] }
              }
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    auth_users(
      limit: $limit
      offset: $offset
      order_by: { email: asc }
      where: {
        _and: [
          { isDeleted: { _eq: false } }
          {
            _not: {
              user_roles: {
                roleId: { _in: ["598c58a6-23cd-48a1-899a-d6f95c73aebc"] }
              }
            }
          }
        ]
      }
    ) {
      id
      email
    }
  }
`;

export const SEARCH_ALL_USERS = `
  query getAllUsers($limit: Int!, $offset: Int!, $search: String!) {
    auth_users_aggregate: auth_search_members_aggregate(
      args: { search: $search }
      order_by: { email: asc }
      where: {
        _and: [
          { isDeleted: { _eq: false } }
          {
            _not: {
              user_roles: {
                roleId: { _in: ["598c58a6-23cd-48a1-899a-d6f95c73aebc"] }
              }
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    auth_users: auth_search_members(
      args: { search: $search }
      limit: $limit
      offset: $offset
      order_by: { email: asc }
      where: {
        _and: [
          { isDeleted: { _eq: false } }
          {
            _not: {
              user_roles: {
                roleId: { _in: ["598c58a6-23cd-48a1-899a-d6f95c73aebc"] }
              }
            }
          }
        ]
      }
    ) {
      id
      email
    }
  }
`;

export const GET_USER = `
  query getUser($id: uuid!) {
    auth_users(where: { id: { _eq: $id } }) {
      id
      firstName
      lastName
      email
      picture
      user_roles {
        role {
          name
        }
      }
      organization {
        id
        name
      }
    }
  }
`;

export const CHANGE_EMAIL = `
  mutation changeEmail($id: uuid!, $email: String!) {
    update_auth_users(where: { id: { _eq: $id } }, _set: { email: $email }) {
      affected_rows
    }
  }
`;

export const LOGOUT = `
  mutation logout($userId: String!) {
    logout(userId: $userId) {
      success
    }
  }
`;

