export enum Roles {
  ORG_ADMIN = 'org_admin',
  USER = 'user',
  ROLLOUT_REP = 'rollout_rep',
  ADMIN = 'admin',
}

export enum Modes {
  Edit = 'EDIT',
  Add = 'ADD',
}

export enum Status {
  ACTIVE = 'Active',
  INVITED = 'Invited',
}

export const SortMembers: Record<string, string> = {
  NameAsc: 'Sort Name in Asc Order',
  NameDesc: 'Sort Name in Desc Order',
  EmailAsc: 'Sort Email in Asc Order',
  EmailDesc: 'Sort Email in Desc Order',
  GroupAsc: 'Sort Group in Asc Order',
  GroupDesc: 'Sort Group in Desc Order',
};

export enum SurveyTabs {
  IN_PROGRESS = 'In Progress',
  SCHEDULED = 'Scheduled',
  COMPLETED = 'Completed',
  ARCHIVED = 'Archived',
}

export enum SurveyStatus {
  IN_PROGRESS = 'in_progress',
  SCHEDULED = 'scheduled',
  COMPLETED = 'completed',
}

export enum SurveyActions {
  CHANGE_END_DATE = 'CHANGE_END_DATE',
  REMIND_UNRESPONSIVE = 'REMIND_UNRESPONSIVE',
  ADD_REMOVE_PARTICIPANTS = 'ADD_REMOVE_PARTICIPANTS',
  ARCHIVE = 'ARCHIVE',
  UN_ARCHIVE = 'UN_ARCHIVE',
  START_SURVEY = 'START_SURVEY',
  UPDATE_SCHEDULE = 'UPDATE_SCHEDULE',
  REMOVE = 'REMOVE',
  RE_OPEN = 'RE_OPEN',
  END_SURVEY = 'END_SURVEY',
  ADD_PARTICIPANTS = 'ADD_PARTICIPANTS',
}

export enum MemberSurveyStatus {
  IN_PROGRESS = 'in_progress',
  PENDING = 'pending',
  COMPLETED = 'completed',
}

export const RolloutStatus: Record<string, string> = {
  OUT_REACH_1_1: 'OUTREACH Phase 1.1 - Identification',
  OUT_REACH_1_2: ' OUTREACH Phase 1.2 - Proposal/Review',
  OUT_REACH_1_3: 'OUTREACH Phase 1.3 - Verbal Commitment',
  OUT_REACH_1_4: 'OUTREACH Phase 1.4 - Awarded/Declined',
  ROLLOUT_PHASE_2_1: 'ROLLOUT Phase 2.1 - Orientation',
  ROLLOUT_PHASE_2_2:
    'ROLLOUT Phase 2.2  - Onboarding the Four Pillars Administrator',
  ROLLOUT_PHASE_2_3: 'ROLLOUT Phase 2.3 - Onboarding the Wellness Team',
  ROLLOUT_PHASE: 'ROLLOUT Phase 3.1 - Activating your Wellness Team',
  ROLLOUT_PHASE_3_2:
    'ROLLOUT Phase 3.2 - Creating your Customized Rollout Plan',
  ROLLOUT_PHASE_3_3: 'ROLLOUT Phase 3.3 - Preparing for your Teacher Training',
  ROLLOUT_PHASE_3_4: 'ROLLOUT Phase 3.4 - Initiating your Classroom Rollout',
  ROLLOUT_PHASE_3_5:
    'ROLLOUT Phase 3.5 - Understanding your Schoolwide Survey Data & Creating Strategies for Wellbeing',
  STAGE_4: 'YEAR 2 TAKEOFF Stage 4',
  PHASE_4_1: 'PHASE 4.1 - Takeoff Introduction',
  PHASE_4_2: 'PHASE 4.2 - Takeoff Year 2',
  PHASE_4_3: 'PHASE 4.3 - Takeoff Year 3',
  COHORT_PHASE_A: 'COHORT PHASE A - Onboarding',
  COHORT_PHASE_B: 'COHORT PHASE B - Wellness Team Level 2 Certification',
  COHORT_PHASE_C: 'COHORT PHASE C - Rollout Rep PD Calls',
  COHORT_PHASE_D: 'COHORT PHASE D - Reflection & Renewal',
  CONTEXTUALIZED_TRAINING_BHUTAN: 'Contextualized Training (Bhutan)',
};

export const FamilyRolloutStatus: Record<string, string> = {
  ROLLOUT_NOT_STARTED_YET: 'Rollout Not Started Yet',
  ROLLOUT_STARTRD: 'Rollout Started',
};

export const CommunicationStatus: Record<string | number, string> = {
  '0': '0 - Have received no communication back from school',
  '1': '1 - School has communicated desire to hold off/pause rollout',
  '2': '2 - Communication has suddenly slowed or stopped',
  '3': '3 - Actively communicating with School Leader',
  '4': '4 - Actively communicating with FPA',
  '5': '5 - Have communicated with Wellness Team',
  '6': '6 - All lines of communication with the school are open',
  '7': '7 - School Deemed Non-Responsive',
};

export const RoleMap: Record<string, string> = {
  user: 'Teacher',
  org_admin: 'Four Pillar Admin',
  admin: 'Super Admin',
};

export const CertificationMap: Record<string, Record<string, string>> = {
  level_one: {
    name: 'Level 1',
    color: 'green'
  },
  level_two: {
    name: 'Level 2',
    color: 'cyan'
  }
};

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_TIME_FORMAT = 'YYYY-MM-DD, h:mm';

export const COHORT_DAY_FORMAT = 'dddd';
export const COHORT_TIME_FORMAT = 'h:mm a';
export const COHORT_DATE_INPUT_FORMAT = `MMM DD, YYYY`;

export const TIME_INPUT_FORMAT = 'HH:mm';
export const COHORT_DETAILS_TIME_FORMAT = 'MMM DD, YYYY (h:mm a)';

export const DAY_ORDER: Record<string, number> = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6,
};

export const SchoolPlans: Record<string, string> = {
  prek: 'Pre-k',
  elementary: 'Elementary',
  hs: 'High School',
  prek_elem: 'Pre-K & Elementary',
  elem_hs: 'Elementary & High School',
  prek_hs: 'Pre-K & High School',
  all: 'All',
};

export const SchoolPlanValues: Record<string, string> = {
  prek: 'prek',
  elementary: 'elementary',
  hs: 'hs',
  prek_elem: 'prek_elem',
  elem_hs: 'elem_hs',
  prek_hs: 'prek_hs',
  all: 'all',
};

export const MONTH_YEAR_FORMAT = `MMM'YY`;

export enum NotificationTypes {
  ROLLOUT_STATUS,
  NO_SURVEY_IN_LAST_SIX_MONTHS_REMINDER,
  SCHOOL_SUBSCRIPTION_RENEWAL,
  SCHOOL_SUBSCRIPTION_SUCCESSFULLY_RENEWED,
  NO_SURVEY_IN_MONTH_FOR_NEW_SCHOOL,
}

export const ROLE_IDS: Record<string, string> = {
  org_admin: '1e9fb264-c630-4d9d-b843-0ffcdf3f0359',
  user: '7fd8d0db-0fd8-44b0-aa6d-db9e9a0a107f',
};

export enum Subscriptions {
  SchoolPlatformSubscription = 'SchoolPlatformSubscription',
  FamilyAppSubscription = 'FamilyAppSubscription',
  SchoolAndFamilyAppSubscription = 'SchoolAndFamilyAppSubscription',
}

export enum SubscriptionType {
  Paid = 'Paid',
  Free = 'Free',
}

export interface subscriptionDataTypes {
  id: string;
  subId: string;
  subType: string;
  subSystem: string;
  start: number;
  end: number;
  coupon: string | null;
  subscriptionalRenewalCode?: string | null;
  customer: string;
  amount: number;
  createdAt: number;
  plan: string;
  quantity: number;
  status: boolean;
  updatedAt: number;
  subscriptionHistory?: JSON;
  autoRenewalDisabledAt: string;
  organization: {
    id: string;
    name: string;
    deletedTime?: string;
  };
}

export enum CancelSubscriptionViews {
  School = 'School',
  Subscription = 'Subscription',
}

export enum RenewSubscriptionViews {
  Expired = 'Expired',
  Upcoming = 'Upcoming',
}

export enum CommentTypes {
  SubscriptionComments = 'subscription-comments',
}

export enum CodeTypes {
  INVITE_CODE = 'INVITE_CODE',
  STRIPE_COUPON = 'STRIPE_COUPON',
}

export interface donationDataType {
  id: string;
  name: string;
  createdAt: string;
  donationAmount: number;
  fulfilmentDate: number;
  totalDonatedLicenses: number;
  termsAndConditions: string;
  donation_type_maps: {
    donation_type: {
      id: string;
      type: string;
    };
  };
  donation_invite_code_maps: {
    invite_code: {
      id: string;
      name: string;
    };
  };
}

export enum InviteCodeTabs {
  SINGLE_USAGE_CODE = 'SINGLE_USAGE_CODE',
  MULTIPLE_USAGE_CODE = 'MULTIPLE_USAGE_CODE',
}

export enum UserDevices {
  TABLET = 'tablet',
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export enum InviteCodeType {
  DISCOUNT = 'DISCOUNT',
  WIRE_TRANSFER = 'WIRE_TRANSFER',
}

export const CurriculumLength: number = 4;

export const DB_DATE_FORMAT = 'YYYY-MM-DD';
