import React from 'react';

interface SideBarContextInterface {
  isOpen?: boolean;
  setIsOpen: (arg0: boolean) => void;
}

const SideBarContext = React.createContext<SideBarContextInterface>({
  isOpen: true,
  setIsOpen: () => {},
});

export const SideBarContextProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(true);

  return (
    <SideBarContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </SideBarContext.Provider>
  );
};

export default SideBarContext;
