import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';

const { Footer, Content } = Layout;
import { Logo } from './NavBarLayout';
import Flex from '../components/Flex';

const Dot = styled.div`
  margin: 5px 10px;
  border-radius: 50%;
  height: 5px;
  width: 5px;
  background: #697386;
`;

const StyledLayout = styled(Layout)`
  background: linear-gradient(8deg, rgb(249, 249, 249) 50%, #fff 50%);
`;

const PublicRoutesLayout: React.FC = ({ children }) => (
  <StyledLayout style={{ height: '100vh' }}>
    <Content>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        wrap="nowrap"
        style={{ height: '100%', overflow: 'auto' }}
      >
        <Link to="/">
          <Logo
            alt="logo"
            src="https://s3.us-east-2.amazonaws.com/tcf-assets/logo-tcf-color.svg"
            style={{
              height: 50,
              margin: '30px 0px',
            }}
          />
        </Link>

        {children}
      </Flex>
    </Content>
    <Footer
      style={{
        background: 'transparent',
      }}
    >
      <Flex justifyContent="center" alignItems="center">
        <span>
          &copy; The Contentment Foundation'{new Date().getFullYear()}
        </span>
        <Dot />
        <a href="https://contentment.org/privacy" target="_blank">
          Privacy Policy
        </a>{' '}
        <Dot />
        <a href="https://contentment.org/tos" target="_blank">
          Terms and Conditions
        </a>
        <Dot />
        <a href="https://www.contentment.org/cookie" target="_blank">
          Cookie Policy
        </a>
      </Flex>
    </Footer>
  </StyledLayout>
);

export default PublicRoutesLayout;
