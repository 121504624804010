import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Avatar, Menu, Dropdown, Divider } from 'antd';
import {
  CaretDownFilled,
  BulbOutlined,
  LogoutOutlined,
  DashboardFilled,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserSwitchOutlined,
  BankOutlined,
  BarChartOutlined,
  TagOutlined,
  SisternodeOutlined,
  MailOutlined,
  FileTextOutlined,
  ProfileOutlined,
  BgColorsOutlined,
  PartitionOutlined,
  LaptopOutlined,
  FundOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useMutation } from 'urql';

import UserContext from '../contexts/UserContext';
import AuthContext from '../contexts/AuthContext';
import SideBarContext from '../contexts/SideBarContext';
import Flex from '../components/Flex';
import {
  FlexHeader,
  AvatarMenuContainer,
  NameContainer,
  Logo,
  StyledSider,
  StyledHeader,
  StyledSideMenu,
} from './NavBarLayout';
import { UserType } from '../types';
import Loader from '../components/Loader';
import { LOGOUT } from '../graphql/user';
import NotificationBadge from '../components/NotificationBadge';
import useEvent from '../contexts/EventContext';
import { EventResources, Events } from '../constants/events';

const AppRoutesLayout: React.FC = ({ children }) => {
  const [saving, setSaving] = React.useState(false);
  const { user: userData, loading: fetchingUser } = React.useContext(
    UserContext,
  );
  const { auth, setAuth } = React.useContext(AuthContext);
  const { isOpen, setIsOpen } = React.useContext(SideBarContext);
  const location = useLocation();
  const [_, logout] = useMutation(LOGOUT);
  const { registerEvent } = useEvent();

  const handleLogout = async () => {
    setSaving(true);
    await logout({
      userId: auth?.id || '',
    });
    registerEvent({
      eventType: Events.LOGOUT,
      eventData: {
        resourceID: auth?.id || '',
        resourceType: EventResources.USER,
        message: `User ${user.email} successfully logged out`,
      },
    });
    setSaving(false);
    setAuth({ id: auth?.id });
  };

  const menu = (
    <Menu>
      {/*<Menu.Item key="0">
        <Link to="/profile">
          <span>
            <IdcardOutlined />
            &nbsp;&nbsp;Profile
          </span>
        </Link>
      </Menu.Item>
      <Menu.Divider />*/}
      <Menu.Item key="1">
        <Link
          to="http://help.contentment.org/"
          target="_blank"
          style={{ color: '#2FC0CE' }}
        >
          <span>
            <BulbOutlined type="bulb" />
            &nbsp;&nbsp;Help Center
          </span>
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" onClick={handleLogout}>
        <span>
          <LogoutOutlined />
          &nbsp;&nbsp;Sign Out
        </span>
      </Menu.Item>
    </Menu>
  );

  const renderSideMenu = (shouldCollapse = true) => {
    const selectedRoute = location.pathname;

    return (
      <StyledSideMenu
        defaultSelectedKeys={[selectedRoute]}
        mode="inline"
        onClick={() => {
          if (shouldCollapse === false) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <Menu.Item key="/" title="Dashboard">
          <Link to="/">
            <DashboardFilled />
            {isOpen && (
              <span
                style={{
                  position: 'absolute',
                  fontSize: '14px',
                }}
              >
                Dashboard
              </span>
            )}
          </Link>
        </Menu.Item>
        {userData?.isAdmin && (
          <Menu.Item key="/rollout-reps" title="Rollout Reps">
            <Link to="/rollout-reps">
              <UserSwitchOutlined />
              {isOpen && (
                <span
                  style={{
                    position: 'absolute',
                    fontSize: '14px',
                  }}
                >
                  Rollout Reps
                </span>
              )}
            </Link>
          </Menu.Item>
        )}
        {userData?.isAdmin && (
          <Menu.Item key="/schools" title="Schools">
            <Link to="/schools">
              <BankOutlined />
              {isOpen && (
                <span
                  style={{
                    position: 'absolute',
                    fontSize: '14px',
                  }}
                >
                  Schools
                </span>
              )}
            </Link>
          </Menu.Item>
        )}
        {userData?.isAdmin && (
          <Menu.Item key="/school/signup" title="School Signup">
            <Link to="/school/signup">
              <EditOutlined />
              {isOpen && (
                <span
                  style={{
                    position: 'absolute',
                    fontSize: '14px',
                  }}
                >
                  School Signup
                </span>
              )}
            </Link>
          </Menu.Item>
        )}
        {userData?.isAdmin && (
          <Menu.SubMenu
            key="/subscriptions"
            icon={<ProfileOutlined />}
            title="Subscriptions"
          >
            <Menu.Item
              key="/subscriptions/renewal"
              title="Subscription Renewal"
            >
              <Link to="/subscriptions/renewal">Renewal</Link>
            </Menu.Item>
            <Menu.Item
              key="/subscriptions/expired"
              title="Expired Subscriptions"
            >
              <Link to="/subscriptions/expired">Expired</Link>
            </Menu.Item>
            <Menu.Item key="/subscriptions/deactivated" title="Deactivated">
              <Link to="/subscriptions/deactivated">Deactivated</Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}
        {userData?.isAdmin && (
          <Menu.Item key="/donations" title="Donations">
            <Link to="/donations">
              <BgColorsOutlined />
              {isOpen && (
                <span
                  style={{
                    position: 'absolute',
                    fontSize: '14px',
                  }}
                >
                  Donations
                </span>
              )}
            </Link>
          </Menu.Item>
        )}
        {userData?.isAdmin && (
          <Menu.Item key="/invite-codes" title="Invite Codes">
            <Link to="/invite-codes">
              <PartitionOutlined />
              {isOpen && (
                <span
                  style={{
                    position: 'absolute',
                    fontSize: '14px',
                  }}
                >
                  Invite Codes
                </span>
              )}
            </Link>
          </Menu.Item>
        )}
        {userData?.isAdmin && (
          <Menu.Item key="/stripe-coupons" title="Stripe Coupons">
            <Link to="/stripe-coupons">
              <TagOutlined />
              {isOpen && (
                <span
                  style={{
                    position: 'absolute',
                    fontSize: '14px',
                  }}
                >
                  Stripe Coupons
                </span>
              )}
            </Link>
          </Menu.Item>
        )}
        {userData?.isAdmin && (
          <Menu.Item key="/canary-rollout" title="Canary Rollout">
            <Link to="/canary-rollout">
              <SisternodeOutlined />
              {isOpen && (
                <span
                  style={{
                    position: 'absolute',
                    fontSize: '14px',
                  }}
                >
                  Canary Rollout
                </span>
              )}
            </Link>
          </Menu.Item>
        )}
        {userData?.isAdmin && (
          <Menu.Item key="/otp" title="OTP">
            <Link to="/otp">
              <FileTextOutlined />
              {isOpen && (
                <span
                  style={{
                    position: 'absolute',
                    fontSize: '14px',
                  }}
                >
                  OTP
                </span>
              )}
            </Link>
          </Menu.Item>
        )}
        {userData?.isAdmin && (
          <Menu.Item key="/cohort-trainings" title="Cohort Trainings">
            <Link to="/cohort-trainings">
              <LaptopOutlined />
              {isOpen && (
                <span
                  style={{
                    position: 'absolute',
                    fontSize: '14px',
                  }}
                >
                  Cohort Trainings
                </span>
              )}
            </Link>
          </Menu.Item>
        )}
        {userData?.isAdmin && (
          <Menu.Item key="/cohort-dashboard" title="Cohort Dashboard">
            <Link
              to={{
                pathname:
                  'https://contentment-org-cohort-dashboard-about-7q84g5.streamlit.app/',
              }}
              target="_blank"
            >
              <FundOutlined />
              {isOpen && (
                <span
                  style={{
                    position: 'absolute',
                    fontSize: '14px',
                  }}
                >
                  Cohort Dashboard
                </span>
              )}
            </Link>
          </Menu.Item>
        )}
        {userData?.isAdmin && (
          <Menu.SubMenu
            key="/data-dashboard"
            icon={<BarChartOutlined />}
            title="Data Dashboard"
          >
            <Menu.Item key="/data-dashboard/guide" title="Schools">
              <Link to="/data-dashboard/guide">Guide</Link>
            </Menu.Item>
            <Menu.Item key="/data-dashboard/schools" title="Schools">
              <Link to="/data-dashboard/schools">Schools</Link>
            </Menu.Item>
            <Menu.Item key="/data-dashboard/users" title="Schools">
              <Link to="/data-dashboard/users">Users</Link>
            </Menu.Item>
            <Menu.Item key="/data-dashboard/survey" title="Schools">
              <Link to="/data-dashboard/survey">Survey</Link>
            </Menu.Item>
            <Menu.Item key="/data-dashboard/cohorts" title="Cohorts">
              <Link to="/data-dashboard/cohorts">Cohorts</Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}
        {userData?.isAdmin && (
          <Menu.Item key="/events" title="Events">
            <Link to="/events">
              <LaptopOutlined />
              {isOpen && (
                <span
                  style={{
                    position: 'absolute',
                    fontSize: '14px',
                  }}
                >
                  Events
                </span>
              )}
            </Link>
          </Menu.Item>
        )}
      </StyledSideMenu>
    );
  };

  if (fetchingUser || !userData || saving) {
    return <Loader height="100vh" width="100vw" />;
  }

  const user: UserType = userData as UserType;
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <StyledHeader>
        <Flex alignItems="center" justifyContent="space-between" wrap="nowrap">
          <Flex alignItems="center" wrap="nowrap" style={{ marginLeft: 30 }}>
            {isOpen ? (
              <MenuFoldOutlined
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              />
            ) : (
              <MenuUnfoldOutlined
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              />
            )}
            <Link to="/">
              <Logo
                alt="logo"
                src="https://s3.us-east-2.amazonaws.com/tcf-assets/logo-tcf-color.svg"
              />
            </Link>
          </Flex>
          <FlexHeader
            alignItems="center"
            wrap="nowrap"
            justifyContent="space-between"
          >
            <NotificationBadge userId={user.id} />
            <Divider
              type="vertical"
              style={{ paddingRight: 10, paddingLeft: 10 }}
            />
            <AvatarMenuContainer>
              <Dropdown overlay={menu} trigger={['click']}>
                <Flex alignItems="center" wrap="nowrap">
                  {user.picture ? (
                    <Avatar src={user.picture} />
                  ) : (
                    <Avatar
                      style={{
                        backgroundColor: '#f56a00',
                        verticalAlign: 'middle',
                      }}
                    >
                      {(user.firstName || user.email).charAt(0)}
                    </Avatar>
                  )}
                  <NameContainer>{user.firstName} </NameContainer>
                  <CaretDownFilled style={{ color: 'rgb(129, 141, 150)' }} />
                </Flex>
              </Dropdown>
            </AvatarMenuContainer>
          </FlexHeader>
        </Flex>
      </StyledHeader>
      <Layout style={{ paddingTop: 64 }}>
        <StyledSider trigger={null} collapsible collapsed={!isOpen}>
          {renderSideMenu(true)}
        </StyledSider>

        <Layout
          style={{
            left: isOpen ? 200 : 80,
            position: 'fixed',
            height: 'calc(100vh - 70px)',
            width: isOpen ? 'calc(100vw - 200px)' : 'calc(100vw - 80px)',
            overflow: 'auto',
          }}
        >
          <Layout.Content
            style={{
              position: 'relative',
            }}
          >
            {children}
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppRoutesLayout;
